.page-template-home-b {
	.container {
		padding-left: 15px;
		padding-right: 15px;
		@include desktop {
			padding-left: 0;
			padding-right: 0;
		}
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
	  -webkit-text-fill-color: #fff;
	  transition: background-color 5000s ease-in-out 0s;
	}

	.header-main {
		@include desktop {
			margin-bottom: 46px;
		}
	}

	.section-header h2 {
		color: #87212E;

		span {
			color: #4F2C1D;
		}
	}

	.hero-form-description {
		color: #4f2c1d;
		font-weight: 900;
		font-size: 16px;
		line-height: 22px;
		padding: 0 0 22px;
		text-align: center;
	}

	.hero-cta-btn {
		padding: 20px 0 30px;
		text-align: center;
	}

	.why-earn-item h3 {
		@include desktop {
			min-height: 100px;
		}
	}

	.our-awards h2 {
		text-transform: none;
	}
}