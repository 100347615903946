.hero {
	background-size: 0;
	padding-top: 34px;
	position: relative;

	@include media-min(768px) {
		padding-top: 80px;
	}

	@include desktop {
		background-position: 80% 0;
		background-repeat: no-repeat;
		background-size: cover;
		border-bottom: 18px solid #FFC54E;
		height: 710px;
		padding-top: 0;
	}

	@include media-min(1200px) {
		background-position: center 0;
	}

	&-wrapper {
		position: relative;

		@include desktop {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}

		@include desktop {
			&-fix-height {
				height: 620px;
			}
		}
	}

	&-scroll {
		background: url(../img/icons/icon-scroll.svg) no-repeat center;
		cursor: pointer;
		display: none;
		height: 86px;
		position: absolute;
		right: -39px;
		top: calc(50% - 43px);

		@include media-min(1040px) {
			right: 0;
			display: block;
			width: calc((100vw - 960px) / 2);
		}

		@include media-min(1200px) {
			width: calc((100vw - 1140px) / 2);
		}

		@include media-min(1400px) {
			width: calc((100vw - 1140px) / 2 - 57px);
		}

		@include media-min(1440px) {
			width: 93px;
		}
	}

	&-description {
		padding-bottom: 30px;
		text-align: center;

		@include desktop {
			max-width: 360px;
			padding-bottom: 0;
			text-align: left;
		}

		h1 {
			color: #4F2C1D;
			font: 400 30px/36px $default-font;
			margin: 0 0 15px;
			padding: 30px 0 0;
			position: relative;

			@include desktop {
				font: 400 48px/58px $default-font;
				margin: 0 0 30px;
				padding: 57px 0 0;
			}

			&::before {
				background: #FCAE42;
				border-radius: 12px 0 12px 0;
				content: '';
				height: 15px;
				left: calc(50% - 29px);
				position: absolute;
				transform: skewX(-15deg);
				top: 0;
				width: 58px;

				@include desktop {
					height: 27px;
					left: 0;
					width: 108px;
				}
			}

			strong {
				display: block;
			}
		}

		p {
			color: #4F2C1D;
			font-weight: 900;
			font-size: 18px;
			line-height: 25px;

			@include desktop {
				font-size: 22px;
				line-height: 30px;
			}
		}
	}

	&-form {
		background: #87212E;
		border-radius: 10px;
		margin: auto;
		max-width: 465px;
		padding: 21px 17px;

		@include desktop {
			border-radius: 0 0 10px 10px;
			height: 719px;
			margin: 0 -30px 0 0;
			padding: 40px 57px 48px;
		}

		@include media-min(1040px) {
			margin: 0;
		}

		@include media-min(1400px) {
			margin-right: -57px;
		}
		
		.thank-you-text {
			color: #fff;
		}
		
		&-title {
			color: #fff;
			font: 27px/37px $default-font;
			margin: 0 0 11px;

			@include desktop {
				font: 38px/52px $default-font;
				margin: 0 0 17px;
			}

			strong {
				display: block;
			}
		}

		.form-consent {
			display: flex;
			padding: 0 0 20px;
			position: relative;

			input[type="checkbox"] {
				cursor: pointer;
				display: none;
				left: 0;
				height: initial;
				margin-bottom: 0;
				padding: 0;
				position: absolute;
				top: 0;
				width: initial;

				&:checked + p::before {
					background: #fff url(../img/icons/icon-checkbox.svg) no-repeat center;
					background-size: 11px 9px;

					@include desktop {
						background-size: 16px 13px;
					}
				}
			}

			p {
				color: #fff;
				font: 400 11px/15px $default-font;
				padding: 0 0 0 26px;
				position: relative;

				@include desktop {
					padding: 0 0 0 44px;
				}

				&:before {
					background: #fff;
					border-radius: 3px;
					content:'';
					cursor: pointer;
					display: inline-block;
					height: 16px;
					left: 0;
					position: absolute;
					top: 0;
					width: 16px;

					@include desktop {
						height: 24px;
						width: 24px;
					}
				}

				a {
					color: #fff;
					font-weight: 700;
					text-decoration: underline;
					
					&:hover {
						text-decoration: none;
					}
				}
			}
		}

		.form-footer {
			button[type="submit"],
			.form-button-next {
				align-self: flex-start;
				background: #fff;
				border: none;
				border-radius: 3px;
				color: #87212E;
				display: inline-flex;
				font-weight: 900;
				font-size: 18px;
				line-height: 25px;
				padding: 16px 51px 15px 20px;
				position: relative;
				transition: all .25s ease;
				white-space: nowrap;

				@include desktop {
					font-size: 24px;
					line-height: 33px;
					padding: 12px 78px 12px 20px;
				}

				&::after {
					background: #87212E;
					content: '';
					height: 2px;
					position: absolute;
					right: 14px;
					top: calc(50% - 1px);
					width: 21px;

					@include desktop {
						right: 24px;
					}
				}

				&::before {
					background: #87212E;
					border-radius: 50%;
					content: '';
					height: 6px;
					position: absolute;
					right: 14px;
					top: calc(50% - 3px);
					width: 6px;

					@include desktop {
						right: 24px;
					}
				}

				&:hover {
					background: rgba(#fff, .85);
					color: #87212E;
				}
			}
		}

		.form-disclaimer {
			p {
				font-size: 11px;
				line-height: 15px;
				text-align: left;
				margin: 12px 0 0 0;
				padding: 0;
				color: #ffffff;

				a {
					color: #fff;
					text-decoration: underline;
				}
			}			
		}
	}
}
