.section-get-started-action-form {
	border-radius: 9px;
	background-color: #fff;
	padding: 28px 15px 20px;

	@include media-min(360px) {
		padding: 33px 36px 20px;
	}

	@include media-min($mobile-max) {
		box-shadow: none;
	}

	@include media-min($desktop-min) {
		padding: 33px 50px 20px;
	}

	.title-form{
		display: block;
		font: 27px/27px 'Avenir LT W01_95 Black1475556', sans-serif;
		margin: 0 0 5px;
		color: #87212e;

		@include media-min($mobile-min) {
			margin: 0 0 20px;
		}

		@include media-min($desktop-min) {
			font-size: 32px;
			line-height: 34px;
			margin: 0 0 3px;
		}
	}

	.input-group {
		padding-top: 2px;
	}

	.form-control {
		background-color: #fcfcfc;
		border-color: #ccc;
		border-radius: 0;
		margin-top: 10px;
		max-width: 100%;
		padding: 13px 22px;
		width: 100%;

		&:nth-child(n + 5) {
			max-width: 100%;
		}

		@include placeholder-color(#666);

		@include media-min($mobile-max) {
			max-width: calc(50% - 10px);
			width: 100%;

			&:nth-child(n + 5) {
				max-width: calc(100% / 3 - 5px);
			}
		}

		@include media-min($desktop-min) {
			margin-top: 20px;
			max-width: calc(50% - 10px);
			padding: 12px 22px;

			&:nth-child(n + 5) {
				max-width: calc(50% - 10px);
			}

		}

		&-wide {
			///max-width: 100% !important;

			///@include media-min($mobile-max) {
			///	max-width: calc(100% / 3 - 5px) !important;
			///}

			@include media-min($desktop-min) {
				max-width: 100% !important;
			}
		}
	}

	select.form-control {
		padding: 5px 19px;
		max-width: 100% !important;

		optgroup {
			display: none;
		}
	}

	.btn-primary[type=submit] {
		display: block;
		margin-top: 24px;
///		min-width: 237px;
		width: 100%;

///		@include media-min($mobile-max) {
///			width: auto;
///		}

		@include media($desktop-min) {
			margin-top: 20px;
		}
	}
}

.section-content-form {
	background: #fff;
	border-top: 4px solid #87212e;
	box-shadow: 0 50px 70px rgba(0, 0, 0, .14);
	margin: 0 0 50px;
	min-width: 100%;
	padding: 25px 15px 26px;
	position: relative;
	z-index: 1;

	@include media-min($mobile-max) {
		float: none !important;
		min-width: 720px;
		padding: 25px 36px 40px;
	}

	@include media-min($desktop-min) {
		box-shadow: 0 50px 70px rgba(0, 0, 0, .14);
		float: right !important;
		margin: 12px 0 50px 80px;
		max-width: 360px;
		min-width: 360px;
		padding: 25px 42px 25px;
		width: 360px;
	}

	&-wide {
		@include media-min($desktop-min) {
			max-width: 100%;
			width: 100%;
		}
	}

	.input-group {
		display: flex;
		flex-wrap: wrap;

		@include media-min($mobile-max) {
			flex-direction: row !important;
			justify-content: space-between;
		}

		@include media-min($desktop-min) {
			flex-direction: column !important;
			justify-content: space-between;
		}

		&-row {
			@include media-min($desktop-min) {
				flex-direction: row !important;
				justify-content: space-between;
			}

			.form-control-wide {
				max-width: 100% !important;
			}
		}

		.form-control{
			background: none;
			height: 50px;
			margin: 10px 0 0;
			width: 100%;
			border-radius: 0;

			@include media-min($mobile-max) {
				max-width: calc(50% - 5px);

				&:nth-child(n + 5) {
					max-width: calc(100% / 3 - 5px);
				}
			}

			@include media-min($desktop-min) {
				max-width: 100% !important;
			}

			&-half {
				max-width: 100% !important;

				@include media-min($tablet-min) {
					max-width: calc(50% - 10px) !important;
				}
			}
		}

		select {
			&.form-control {
				padding: 5px 19px;
				max-width: 100% !important;

				&-half {
					@include media-min($tablet-min) {
						max-width: calc(50% - 10px) !important;
					}
				}

				optgroup {
					display: none;
				}
			}
		}
	}

	p{
		font-size: 13px !important;
		line-height: 17px !important;
		padding: 0 20px;
		text-align: center;
	}

	.title-form{
		display: block;
		font: 21px/32px 'Avenir LT W01_85 Heavy1475544', sans-serif;
		letter-spacing: .2px;
		margin: 0 0 12px !important;
	}

	button[type='submit']{
		display: block;
		margin-top: 24px;
		width: 100%;

		@include media-min($mobile-max) {
			margin: 24px auto 0;
			width: 237px;
		}

		@include media-min($desktop-min) {
			width: 100%;
		}
	}
}

.section-schedule-content-form{
	padding: 30px 25px 20px;

	@include media-min($mobile-max) {
		padding: 61px 55px 20px;
	}

	.input-group {
		padding-top: 2px;
	}

	.form-control {
		background: none;
		border-color: #ccc;
		border-radius: 0;
		margin-top: 10px;
		max-width: 100%;
		padding: 13px 22px;
		width: 100%;

		@include placeholder-color(#666);

		@include media-min($mobile-max) {
			max-width: calc(50% - 10px);
			width: 100%;
		}

		@include media-min($desktop-min) {
			margin-top: 20px;
			padding: 17px 22px;
		}
	}

	button[type='submit']{
		display: block;
		height: 55px;
		margin-top: 37px;
		width: 100%;

		@include media-min($mobile-max) {
			height: 65px;
			margin: 37px auto 0;
			max-width: 310px;
			width: 100%;
		}
	}
}

.label-hidden{
	display: none !important;
}

.thank-you-text {
	display: none;
	font-size: 20px;
	line-height: 22px;
	font-family: 'Avenir LT W01_55 Roman1475520',sans-serif;
	letter-spacing: 1px;
	margin: 2rem 0 13px;
}

.disclaimer {
	p {
		font-size: 11px !important;
		line-height: 15px !important;
		text-align: left;
		margin: 12px 0 0 0 !important;
		padding: 0;
		a {
			color: #000;
			text-decoration: underline;
		}
	}
}
