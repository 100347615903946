.section-header {
	margin: 25px 0 30px;
	position: relative;

	@include desktop {
		margin: 40px 0 80px;
	}

	h2 {
		font: 900 18px/25px $default-font;
		text-transform: none;
		position: relative;
		z-index: 9;

		@include desktop {
			font: 900 27px/37px $default-font;
			max-width: 670px;
		}

		span {
			display: block;
			font-family: Cochin, serif;
			font-weight: 500;
			font-size: 34px;
			line-height: 39px;
			padding: 5px 0 0;

			@include desktop {
				font-size: 72px;
				line-height: 83px;
			}
		}
	}
}