.why-earn {
	background: #F9F8F4;
	padding: 25px 0 35px;
	overflow: hidden;

	@include desktop {
		padding: 50px 0 70px;
	}

	@include desktop {
		.section-header {
			margin-bottom: 55px;

			&::after {
				background: url(../img/bg-section-header-why-earn.svg) no-repeat right 0;
				content: '';
				height: 151px;
				position: absolute;
				right: -258px;
				top: 50px;
				width: 720px;
			}
		}
	}

	&-wrapper {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -30px;
		position: relative;
		z-index: 3;

		@include tablet {
			display: block;
			margin: 0;
		}
	}

	&-icon {
		margin: 0 0 15px;
		padding: 4px;

		@include desktop {
			margin: 0 0 24px;
			padding: 8px;
		}

		svg {
			display: block;
			height: auto;
			max-height: 27px;
			max-width: 27px;

			@include desktop {
				max-height: initial;
				max-width: 100%;
			}

			path {
				fill: #FFC54E;

				@include desktop {
					fill: #87212E;
				}
			}
		}
	}

	&-item {
		background: #fff;
		border-radius: 10px;
		margin: 0 15px 30px;
		padding: 17px 17px 25px;
		width: calc(50% - 30px);

		@include tablet {
			margin: 0 0 15px;
			width: 100%;
		}

		@include desktop {
			background: #fff url(../img/bg-blink-why-earn.svg) no-repeat 40px 0;
			padding: 60px 55px 68px 40px;
		}

		h3 {
			color: #4F2C1D;
			font: 800 16px/24px 'Avenir LT W01_95 Black1475556', sans-serif;
			margin: 0 0 10px;
			text-transform: uppercase;

			@include desktop {
				font: 800 33px/50px 'Avenir LT W01_95 Black1475556', sans-serif;
				margin: 0 0 20px;
			}
		}

		p {
			color: #4F2C1D;
			font: 400 14px/21px $default-font;

			@include desktop {
				font: 400 21px/32px $default-font;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}