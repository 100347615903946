.cta-footer {
	background-position: center 0;
	background-repeat: no-repeat;
	background-size: 0;
	border-top: 18px solid #FFC54E;
	padding: 0 0 391px;
	position: relative;
	text-align: center;

	@include media-min(768px) {
		background-size: cover;
	}

	@include desktop {
		border-top: none;
		padding: 15px 0 10px;
		text-align: left;
	}

	&-bg-mobile {
		bottom: 0;
		display: flex;
		left: 0;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;

		@include media-min(768px) {
			display: none;
		}

		img {
			display: block;
			left: 50%;
			height: 491px;
			margin-top: auto;
			max-width: initial;
			position: relative;
			transform: translateX(-50%);
		}
	}

	.container {
		max-width: 100%;
		padding-left: 0 !important;
		padding-right: 0 !important;
		position: relative;
		z-index: 4;

		@include media-min(992px) {
			max-width: 960px;
			padding-left: 15px !important;
			padding-right: 15px !important;
		}
	}
	
	&-description {
		background: linear-gradient(180deg, #FEF6F4 0%, #FEF6F4 93.43%, rgba(255, 251, 250, 0) 100%);
		padding: 70px 15px 0;

		@include media-min(768px) {
			background: none;
		}

		@include media-min(992px) {
			padding: 0;
		}
	}

	&-btn {
		align-self: center;
		background: #87212E;
		border-radius: 3px;
		color: #fff;
		display: inline-flex;
		font-weight: 900;
		font-size: 24px;
		line-height: 33px;
		padding: 12px 78px 12px 20px;
		position: relative;
		transition: all .25s ease;

		@include desktop {
			align-self: flex-start;
		}

		&::after {
			background: #fff;
			content: '';
			height: 2px;
			position: absolute;
			right: 24px;
			top: calc(50% - 1px);
			width: 21px;
		}

		&::before {
			background: #fff;
			border-radius: 50%;
			content: '';
			height: 6px;
			position: absolute;
			right: 24px;
			top: calc(50% - 3px);
			width: 6px;
		}
		
		&:hover {
			background: rgba(#87212E, .85);
			color: #fff;
		}
	}

	.container {
		flex-direction: column;
		display: flex;
		justify-content: center;

		@include desktop {
			height: 667px;
		}
	}

	h2 {
		color: #4F2C1D;
		font: 400 30px/36px $default-font;
		margin: 0 0 15px;
		padding: 30px 0 0;
		position: relative;

		@include desktop {
			font: 400 48px/58px $default-font;
			margin: 0 0 30px;
			max-width: 470px;
			padding: 57px 0 0;
		}

		&::before {
			background: #FCAE42;
			border-radius: 12px 0 12px 0;
			content: '';
			left: calc(50% - 29px);
			height: 15px;
			position: absolute;
			transform: skewX(-15deg);
			top: 0;
			width: 58px;

			@include desktop {
				left: 0;
				height: 27px;
				width: 108px;
			}
		}
	}

	p {
		color: #4F2C1D;
		font-weight: 900;
		font-size: 18px;
		line-height: 25px;
		margin: 0 0 15px;

		@include desktop {
			font-size: 22px;
			line-height: 30px;
			margin: 0 0 30px;
			max-width: 448px;
		}
	}
}