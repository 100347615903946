.video {
	padding: 0 0 90px;

	h2 {
		color: #4f2c1d;
		font-family: Cochin,serif;
		font-weight: 500;
		font-size: 34px;
		line-height: 39px;
		margin: 0 0 32px;
		text-align: center;
		text-transform: initial;

		@include desktop {
			font-size: 72px;
			line-height: 83px;
		}
	}

	&-wrapper {
		position: relative;
		padding-bottom: 60.5%;
		height: 0;
		max-width: 730px;
		margin: auto;

		@include desktop {
			padding-bottom: 46%;
		}

		@include media-min(1200px) {
			padding-bottom: 40%;
		}

		iframe {
			border-radius: 20px;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}