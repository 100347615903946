.form-steps {
	width: 100%;

	@include desktop {
		&.hero-form {
			border-radius: 10px;
			height: 568px;
			margin-top: 80px;
			padding: 40px 46px 48px;
			overflow: hidden;
			transition: all .4s ease;

			&.hero-form-step-2 {
				height: 607px;
			}

			&.with-subtitle {
				height: 610px;

				&.hero-form-step-2 {
					height: 650px;
				}
			}
		}
	}

	&-header {
		display: flex;
		justify-content: space-between;
		margin: 0 0 20px;
		padding: 0 0 31px;
		position: relative;
		text-align: center;

		&::after {
			background: #fff;
			bottom: 0;
			content: '';
			height: 1px;
			left: -17px;
			position: absolute;
			right: -17px;

			@include desktop {
				left: -46px;
				right: -46px;
			}
		}

		&-item {
			background: url("../img/icon-current.svg") no-repeat center;
			background-size: 0 0;
			border-bottom: 5px solid #eadd96;
			color: #eadd96;
			font-weight: 700;
			font-size: 14px;
			line-height: 17px;
			padding: 5px 0;
			transition: all .25s ease;
			width: calc(50% - 4px);

			&.current {
				border-color: #fff;
				color: #fff;
			}

			&.completed {
				background-size: 18px 18px;
				color: transparent;
			}
		}
	}

	.hero-form-sub-title {
		display: block;
		font-size: 16px;
		line-height: 28px;
		color: #eadd96;

		@include media-min($mobile-min) {
			margin: 0 0 20px;
		}

		@include media-min($desktop-min) {
			line-height: 28px;
			margin: -14px 0 14px;
		}
	}

	&-fields {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	& &-footer.form-footer {
		padding: 0;

		.form-button-next {
			cursor: pointer;
			margin: auto;

			@include media-min(400px) {
				margin: 0;
			}
		}

		.form-button-prev {
			align-items: center;
			border: none;
			border-radius: 3px;
			color: #fff;
			cursor: pointer;
			display: inline-flex;
			font-weight: 900;
			font-size: 18px;
			line-height: 25px;
			padding: 12px 20px 12px 0;
			position: relative;
			transition: all .25s ease;

			@include desktop {
				font-size: 20px;
				line-height: 33px;
			}

			&:hover {
				border-color: #fbcb6c
			}

			svg {
				display: block;
				margin-right: 20px;
			}

			&::before,
			&::after {
				display: none;
			}
		}

		button[type="submit"] {
			margin: 15px auto 0;

			@include media-min(400px) {
				margin: 0;
			}

			@include desktop {
				font-size: 22px;
				line-height: 33px;
				padding: 12px 50px 12px 12px;
			}

			&::before,
			&::after {
				@include desktop {
					right: 14px;
				}
			}
		}
	}

	.form-body-step {
		display: none;

		&-1 {
			.form-field {
				margin: 10px 0 30px;
			}

			.form-steps-footer {
				padding: 10px 0 0;
			}
		}
	}

	.form-field {
		width: 100%;

		&.half {
			@include media-min(768px) {
				width: calc(50% - 15px);
			}
		}
		
		label {
			color: #fff;
		}
		
		input {
			border-color: #fff;
		}
	}

	.select {
		&::before {
			border-color: #fff;
		}

		select {
			border-color: #fff;
			color: #fff !important;

			&.error {
				border-color: #f00;
			}
		}
	}
}