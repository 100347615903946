.blockquote {
	margin: 0 15px 25px;
	position: relative;

	@include desktop {
		margin: 0 15px 50px;
	}

	&::after {
		background: #F9F8F4;
		bottom: -50px;
		content: '';
		left: -15px;
		height: 300px;
		position: absolute;
		right: -15px;
	}

	&-wrapper {
		background: #87212E;
		border-radius: 20px;
		margin: auto;
		max-width: 1343px;
		overflow: hidden;
		position: relative;
		z-index: 3;

		@include desktop {
			align-items: center;
			display: flex;
		}
	}

	&-image {
		height: 290px;

		@include desktop {
			height: auto;
			width: calc(50% + 15px);
		}

		img {
			display: block;
			height: 100%;
			object-fit: cover;
			width: 100%;

			@include desktop {
				height: auto;
				max-width: 100%;
				width: auto;
			}
		}
	}

	&-description {
		padding: 26px 15px;

		@include desktop {
			width: calc(50% - 15px);
		}

		@include media-min(1200px) {
			padding: 65px;
		}

		p {
			color: #fff;
			font: 900 18px/27px $default-font;

			@include desktop {
				font: 900 32px/48px $default-font;
				max-width: 475px;
			}

			&:first-child {
				padding: 30px 0 0;
				position: relative;

				@include desktop {
					padding: 43px 0 0;
				}

				&::before {
					background: #FFC54E;
					border-radius: 6px 0 6px 0;
					content: '';
					height: 15px;
					position: absolute;
					transform: skewX(-15deg);
					top: 0;
					width: 58px;

					@include desktop {
						height: 17px;
						width: 68px;
					}
				}
			}
		}
	}
}