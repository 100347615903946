.form-field {
	margin: 0 0 23px;
	position: relative;

	input:focus,
	input:not(:placeholder-shown) {
		border-color: #fff;

		& + label {
			font-size: 11px;
			top: -13px;
		}
	}

	input:focus{

		& ~ .form-field-active-line {
			box-shadow: 0 0 10px #fff;
		}
	}

	label {
		color: #fff;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		left: 0;
		margin: 0;
		position: absolute;
		top: 13px;
		transition: all .25s ease;
		z-index: 9;

		@include media-min(768px) {
			color: #EADD96;
		}
	}

	&-active-line {
		box-shadow: 0 0 0 #fff;
		content: '';
		left: 0;
		height: 1px;
		position: absolute;
		top: 100%;
		right: 0;
	}

	input {
		background: transparent;
		border: none;
		border-bottom: 1px solid #fff;
		color: #fff !important;
		font-weight: 900;
		font-size: 16px;
		line-height: 22px;
		height: 38px;
		padding: 0;

		@include media-min(768px) {
			border-bottom: 1px solid #EADD96;
		}

		&::placeholder{
			opacity: 0;
		}

		&:focus,
		&:not(:placeholder-shown) {
			box-shadow: none;
			outline: none;
		}

		&.error-input {
			box-shadow: none;

			& ~ .form-field-active-line {
				border-color: #f00;
			}
		}
	}

	.select {
		background: transparent;
		position: relative;
		width: 100%;

		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			background: #87212E;
			border: none;
			border-bottom: 1px solid #fff;
			color: #fff !important;
			font-size: 16px;
			height: auto;
			min-height: 38px;
			padding: 0 45px 0 0;
			line-height: 22px;
			white-space: normal;

			@include media-min(768px) {
				border-bottom: 1px solid #EADD96;
				color: #EADD96 !important;
			}

			&.selected {
				border-bottom: 1px solid #fff;
				color: #fff !important;
				font-weight: 900;
			}

			&:focus,
			&:active {
				outline: none;
			}

			&.error-input {
				box-shadow: none;

				& ~ .form-field-active-line {
					border-color: #f00;
				}
			}
		}

		&::before {
			border: solid #fff;
			border-width: 0 3px 3px 0;
			display: inline-block;
			padding: 3px;
			content: '';
			height: 0;
			position: absolute;
			right: 18px;
			top: calc(50% - 6px);
			transform: rotate(45deg);
			width: 0;
			z-index: 1;

			@include media-min(768px) {
				border: solid #EADD96;
				border-width: 0 3px 3px 0;
			}
		}

		optgroup {
			display: none;
		}
	}

	&-name, &-email, &-phone, &-comments {
		display: none !important;
		left: -9000px;
		position: absolute !important;
	}
}