body.page-template-schedule {

	.section-schedule{
		padding: 40px 0 0;
		position: relative;

		@include media-min($mobile-max) {
			padding: 158px 0 0;
		}

		&-bg{
			background-position: center 0;
			background-repeat: no-repeat;
			background-size: cover;
			height: 392px;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}

		h1{
			color: #fff;
			font: 40px/50px 'Avenir LT W01_95 Black1475556', sans-serif;
			margin: 0 0 40px;
			text-align: center;
			text-transform: uppercase;

			@include media-min($mobile-max) {
				font: 60px/70px 'Avenir LT W01_95 Black1475556', sans-serif;
				margin: 0 0 64px;
			}

			span{
				color: #daa649;
			}
		}

		&-content {
			background: #fff;
			box-shadow: 0 50px 70px rgba(0,0,0,.13);
			flex-wrap: wrap;
			margin: 0 0 45px;
			min-height: 500px;

			@include media-min($mobile-max) {
				margin: 0 0 100px;
			}

			&-text{
				background: #383838;
				color: #fff;
				font: 21px/36px 'Avenir LT W01_55 Roman1475520', sans-serif;
				padding: 25px 30px;
				order: 2;

				@include media-min($mobile-max) {
					padding: 12px 60px 26px 66px;
				}

				@include media-min($desktop-min) {
					order: -1;
				}
			}

			&-form{

				.title-form {
					display: block;
					font: 28px/28px 'Avenir LT W01_95 Black1475556', sans-serif;
					letter-spacing: -1px;
					margin: 0 0 6px;
				}

				.thank-you-text {
					margin: 90px 0;
				}

				p{
					font-size: 18px;
					line-height: 18px;
					margin: 0 0 11px;
				}
			}
		}
	}

	.header-main-btn-group {
		display: none;

		@include media-min($desktop-min) {
			display: block;
		}
	}
}