.direct-entry {
	padding: 25px 0 0;
	overflow: hidden;

	@include desktop {
		padding: 70px 0 56px;
	}

	@include desktop {
		.section-header {
			&::after {
				background: url(../img/bg-section-header-direct-entry.svg) no-repeat right 0;
				content: '';
				height: 181px;
				position: absolute;
				right: -380px;
				top: -60px;
				width: 730px;
			}
		}
	}

	&-item {
		padding: 0 0 50px;

		@include desktop {
			align-items: center;
			display: flex;
			padding: 0 0 98px;
		}

		&:nth-child(2n) {
			flex-direction: row-reverse;

			@include desktop {
				.direct-entry-image {
					margin: 0 65px 0 30px;
				}
			}
		}
	}

	&-image {
		margin: 0 0 30px;
		position: relative;

		@include desktop {
			margin: 0 30px 0 65px;
			width: calc(100% - 350px - 95px);
		}

		img {
			border-radius: 10px;
			display: block;
			height: auto;
			width: 100%;

			@include desktop {
				margin: 0 0 0 auto;
			}
		}
	}

	&-description {
		@include desktop {
			width: 350px;
		}

		p {
			font-size: 16px;
			line-height: 24px;
			margin: 0 0 24px;

			@include desktop {
				font-size: 18px;
				line-height: 27px;
				margin: 0 0 27px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&-virtual-tour {
		background: rgba(#87212E, .8);
		border-radius: 10px 10px 0 0;
		bottom: 0;
		left: 12px;
		max-width: 500px;
		margin: auto;
		padding: 15px 4px;
		position: absolute;
		right: 12px;
		text-align: center;

		@include desktop {
			left: 36px;
			margin: 0;
			max-width: initial;
			padding: 21px 0 22px;
			right: 36px;
		}

		&-container {
			margin: 0 auto;
			max-width: 402px;
		}

		&-button {
			display: flex;
			justify-content: center;
			padding: 0 0 10px;

			@include desktop {
				padding: 0 0 20px;
			}

			img {
				display: block;
				height: auto;
				margin: 0;
				max-width: 100%;
				width: auto;
			}

			a {
				background: #fff;
				border-radius: 5px;
				color: #87212E;
				font-weight: 900;
				font-size: 14px;
				line-height: 19px;
				margin: 0 0 0 10px;
				padding: 12px 20px;
				transition: all .25s ease;

				@include desktop {
					font-size: 16px;
					line-height: 22px;
				}

				&:hover {
					background: rgba(#fff, .8);
				}
			}
		}
		
		h3 {
			color: #fff;
			font: 900 14px/19px $default-font;
			margin: 0 0 10px;
			text-transform: none;

			@include desktop {
				font: 900 16px/22px $default-font;
				margin: 0 0 30px;
			}
		}

		p {
			color: #fff;
			font-weight: 400;
			font-size: 14px;
			line-height: 19px;

			@include desktop {
				font-size: 11px;
				line-height: 15px;
			}
		}
	}
}