.our-awards {
	background: #F9F8F4;
	padding: 0 0 50px;

	@include desktop {
		padding: 0 0 75px;
	}

	&-wrapper {
		background: #fff;
		border-radius: 20px;
		margin: 0 auto;
		max-width: 1140px;
		width: calc(100% - 50px);

		@include desktop {
			display: flex;
			width: 100%;
		}
	}

	h2 {
		color: #4F2C1D;
		display: none;
		font-weight: 800;
		font-size: 33px;
		line-height: 50px;
		margin: 0 0 20px;
		text-align: center;

		@include desktop {
			display: block;
		}
	}

	&-item {
		border-top: 1px solid #D5D5D5;
		flex: 1;
		padding: 29px 62px 26px;
		text-align: center;

		@include desktop {
			border-top: none;
			border-left: 1px solid #D5D5D5;
			padding: 24px 15px 32px;
		}

		&:first-child {
			border: none;
		}

		img {
			display: block;
			height: auto;
			margin: 0 auto;
			max-width: 116px;

			@include desktop {
				max-width: 185px;
			}
		}

		p {
			display: none;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;

			@include desktop {
				display: block;
			}
		}
	}

	&-logo {
		@include desktop {
			align-items: center;
			display: flex;
			height: 247px;
		}
	}
}