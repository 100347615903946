.header-main {
	padding: 17px 15px 21px;
	position: relative;

	@include media-min($mobile-max) {
		padding: 18px 0 20px;
	}

	@include media-min($desktop-min) {
		padding-bottom: 24px;
		padding-top: 20px;
		margin-bottom: 56px;
	}

	&-logo {
		min-width: 192px;
		width: 192px;

		@include media-min($mobile-max) {
			min-width: 228px;
			width: 228px;
		}

		@include media-min($desktop-extra-min) {
			width: 304px;
		}

		img{
			min-width: 192px;
			@include media-min($mobile-max) {
				min-width: initial;
			}
		}
	}

	&-phone {
		background: url("../img/icons/icon-phone.svg") no-repeat right bottom 4px;
		font-size: 0;
		height: 42px;
		margin: 0 21px 0 auto;
		width: 34px;

		@include media-min($mobile-max) {
			background: none;
			font-size: 18px;
			height: auto;
			margin: 0;
			width: auto;
		}

		@include media-min($desktop-min) {
			font-size: 26px;
		}

		a {
			color: $default-color-text;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;

			@include media-min($mobile-max) {
				position: static;
			}
		}
	}

	&-btn-group {
		flex: 0 0 100%;
		padding: 20px 15px 0;
		width: 100%;

		@include media-min($mobile-max) {
			flex: 0 0 40%;
			padding: 0 15px;
			width: auto;
		}

		@include media-min($desktop-min) {
			flex: 0 0 47%;
		}

		.btn, .btn:hover, .btn:active {
			display: block;
			font-size: 17px;
			margin: auto;
			max-width: 100%;
			width: 100%;
			background: transparent !important;
			border: 3px solid $action-color;
			color: $action-color;
			padding-left: 1.7em;
			padding-right: 1.7em;

			@include media-min($mobile-max) {
				display: inline-block;
				font-size: 13px;
				max-width: 236px;
			}

			@include media-min($desktop-min) {
				font-size: 18px;
				max-width: 100%;
				width: auto;
			}
		}
	}

	.classes-start {
		display: none;
		@include media-min($desktop-min) {
			display: inline-block;
		}
	}
	
	.open-nav{
		background: url("../img/icons/icon-open-nav.svg") no-repeat center bottom 7px;
		background-size: 34px 21px;
		display: block;
		height: 42px;
		margin-right: 15px;
		width: 34px;
		cursor: pointer;

		@include media-min($mobile-max) {
			display: none;
		}
	}
}

.notice {
	background: #00587C;
	color: #fff;
	font: 18px/30px $default-font;
	position: relative;
	padding: 12px 47px;
	text-align: center;

	p{
		margin: 0;
	}

	.close {
		align-items: center;
		background: none;
		border-radius: 50%;
		cursor: pointer;
		display: flex;
		height: 33px;
		justify-content: center;
		position: absolute;
		opacity: 1;
		right: 7px;
		top: 4px;
		width: 33px;

		@include media-min($mobile-max) {
			background: rgba(255,255,255,.2);
			top: 50%;
			transform: translateY(-50%);
		}

		img{
			height: 11px;
			width: 11px;
		}
	}
}

.mm-menu_opened {
	z-index: 1;
}
