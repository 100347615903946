body.page-template-default,
body.page-template-refer-a-friend,
body.page-template-email-settings,
body.page-template-blank {

	.section-hero {
		background-position: center 0;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 92px 15px 20px;
		text-align: center;

		@include media($tablet-min) {
			padding: 40px 15px;
		}

		.container {
			height: 1px; /// Fix for IE11 flex align-items bug
			             /// See https://stackoverflow.com/questions/19371626/flexbox-not-centering-vertically-in-ie
			min-height: 392px;
			
			@include media($tablet-min) {
				height: initial;
				min-height: initial;
			}

			h1 {
				color: #fff;
				font: 60px/68px 'Avenir LT W01_95 Black1475556', sans-serif;
				margin: 0;
				text-transform: uppercase;
	
				@include media($tablet-min) {
					font-size: 38px;
					line-height: 42px;
				}
	
				span{
					color: #ffc54e;
					display: block;
				}
			}
		}
	}

	.section-content{
		font: 18px/30px "Avenir LT W01_55 Roman1475520", sans-serif;
		padding: 77px 15px 50px;

		@include media($tablet-min) {
			padding: 45px 15px;
		}

		h2{
			font: 48px/48px 'Avenir LT W01_85 Heavy1475544', sans-serif;
			letter-spacing: -2.3px;
			margin: 0 0 20px;
			text-transform: none;

			@include media($tablet-min) {
				font-size: 30px;
				line-height: 36px;
				letter-spacing: 0;
			}
		}

		h3{
			font: 32px/48px 'Avenir LT W01_85 Heavy1475544', sans-serif;
			letter-spacing: .2px;
			margin: 40px 0 20px;
			text-transform: none;

			@include media($tablet-min) {
				font-size: 24px;
				line-height: 30px;
				letter-spacing: 0;
			}
		}

		h4{
			font: 22px/24px 'Avenir LT W01_85 Heavy1475544', sans-serif;
			letter-spacing: 0;
			margin: 0 0 .5em;
			text-transform: none;

			@include media($tablet-min) {
				font-size: 20px;
				line-height: 24px;
			}
		}

		p{
			font: inherit;
			margin: 0 0 22px;
		}

		ul{
			margin: 0 0 30px;

			li{
				font: inherit;
				margin: 0 0 10px;
				padding: 0 0 0 28px;
				position: relative;

				&:before{
					background: #daa649;
					border-radius: 6px;
					content: '';
					height: 6px;
					left: 0;
					position: absolute;
					top: 11px;
					width: 6px;
				}
			}
		}

		ol{
			counter-reset: my-badass-counter;
			margin: 0 0 30px;
			padding: 0;

			li{
				font: inherit;
				list-style: none;
				margin: 0 0 10px;
				padding: 0 0 0 28px;
				position: relative;

				&:before{
					color: #daa649;
					content: counter(my-badass-counter) ".";
					counter-increment: my-badass-counter;
					display: inline-block;
					font: 18px/30px "Avenir LT W01_55 Roman1475520", sans-serif;
					left: 0;
					position: absolute;
					top: 0;
					vertical-align: top;
					width: 28px;
				}
			}
		}

		img{
			@include media($mobile-min) {
				width: 100%;
			}
		}

		.pr-card {
			&, &:hover, &:focus, &:visited {
				color: #000;
			}

			div {
				transition: transform ease .2s;
			}

			div:hover {
				transform: scale(1.03);
				h4 {
					text-decoration: underline;
				}
			}
		}
	}

	.ddsg-wrapper {
		padding-top: 20px;
	}
	.ddsg-wrapper div:last-child {
		display: none;
	}

}
