body[class*="page-template-home-"] {

	.section-get-started {
		background-repeat: no-repeat;
		background-position: center 0;
		background-size: auto 431px;
		margin: 0 auto;
		max-width: 1440px;
		padding: 48px 20px 0;

		@include tablet() {
			background: #4f2c1d !important;
			padding-bottom: 30px !important;
		}

		@include media-min($mobile-max) {
			background-color: #fff;
			background-size: auto 556px;
			display: flex;
			flex-direction: column;
			padding: 137px 60px 0;
		}

		@include media-min($desktop-min) {
			background-color: #4f2c1d;
			background-size: cover !important;
			padding: 120px 20px 95px !important;
		}

		&-inside {
			width: 100%;
		}

		&-description {
			color: #fff;
			max-width: 100%;
			padding: 0 0 36px;
			text-align: center;
			width: 100%;

			@include media-min($mobile-max) {
				padding: 0 0 44px;
			}

			@include media-min($desktop-min) {
				background: #87212e;
				padding: 2.5em 3.5em;
				width: auto;
				text-align: left;
				border-radius: 9px;
			}

			h1 {
				font-family: "Avenir LT W01_85 Heavy1475544", sans-serif;
				font-size: 38px;
				line-height: 44px;
				margin-bottom: 35px;

				@include media-min($desktop-min) {
					font-size: 2.7em;
					margin-bottom: 0;
				}
			}

			p {
				letter-spacing: 1px;
				margin: 0;

				@include media-min($desktop-min) {
					margin-top: .7em;
					font-size: 1.8em;
					line-height: 1.1em;
				}
			}

			span.months {
				background-color: $action-color;
				font: 21px/1.2 'Avenir LT W01_95 Black1475556', sans-serif;
				letter-spacing: 1px;
				margin: 0;
				padding: 0 8px;
				white-space: nowrap;

				@include media-min($desktop-min) {
					display: inline-block;
					font-size: inherit;
					line-height: inherit;
					///margin: 12px 0 0;
					///padding: 0 17px 0 10px;
				}
			}
		}

		&-action {
			margin-left: auto;
			width: 565px;

			@include media($desktop-min) {
				width: 100%;
			}

///			&-disclaimer {
///				background-color: #353c47;
///				color: #fff;
///				font-family: 'Trade Gothic LT W01 Light', sans-serif;
///				padding: 25px;
///				text-align: center;
///
///				@include media-min($mobile-min) {
///					font-size: 13px;
///					line-height: 18px;
///					padding: 25px 50px;
///				}
///
///				@include media-min($desktop-min) {
///					padding: 27px 67px 27px 50px;
///					text-align: left;
///				}
///
///				&::before {
///					border: 0 solid transparent;
///					border-left-width: 15px;
///					border-right-width: 15px;
///					border-top: 12px solid #fff;
///					content: '';
///					height: 0;
///					left: calc(50% - 15px);
///					position: absolute;
///					top: 0;
///					width: 0;
///				}
///
///				p {
///					font-size: 13px;
///					line-height: 20px;
///				}
///			}
		}
	}

///	.section-statistic {
///		padding: 52px 20px 85px;
///
///		@include media-min($mobile-min) {
///			padding-bottom: 85px;
///			padding-top: 52px;
///		}
///
///		@include media-min($tablet-min) {
///			padding-bottom: 97px;
///			padding-top: 64px;
///		}
///
///		@include media-min($desktop-min) {
///			padding-bottom: 140px;
///			padding-top: 105px;
///		}
///
///		.row {
///			min-width: 100%;
///		}
///
///		h2 {
///			color: #262626;
///			font-size: 30px;
///
///			@include media-min($mobile-max) {
///				font-size: 36px;
///				line-height: 36px;
///			}
///
///			@include media-min($desktop-min) {
///				font-size: 42px;
///				line-height: 42px;
///			}
///
///			& + p {
///				display: block;
///				line-height: 30px;
///				margin-top: 23px;
///				max-width: 650px;
///
///				@include media($desktop-min) {
///					font-size: 16px;
///				}
///			}
///		}
///
///		& > .d-flex {
///			padding-left: 20px;
///			padding-right: 40px;
///
///			@include media($tablet-min) {
///				flex-direction: column;
///			}
///
///			@include media($desktop-extra-min) {
///				padding-left: 0;
///				padding-right: 0;
///			}
///		}
///
///		&-item {
///			margin-top: 34px;
///			max-width: 470px;
///
///			@include media($tablet-min) {
///				max-width: 100%;
///			}
///
///			@include media($desktop-extra-min) {
///				justify-content: flex-start !important;
///			}
///
///			span {
///				margin-right: 58px;
///				width: 103px;
///
///				@include media($tablet-min) {
///					min-width: 70px;
///				}
///
///				@include media($desktop-extra-min) {
///					margin-right: 33px;
///					width: 70px;
///				}
///			}
///
///			&-data {
///				max-width: 100%;
///
///				@include media-min($mobile-max) {
///					max-width: 310px;
///				}
///
///				h3 {
///					color: $action-color;
///					font-family: 'Trade Gothic LT W01 Light', sans-serif;
///					font-size: 40px;
///					font-weight: lighter;
///					letter-spacing: 5px;
///					line-height: 1;
///					margin-bottom: 7px;
///
///					@include media-min(360px) {
///						font-size: 52px;
///					}
///
///					@include media-min($desktop-extra-min) {
///						font-size: 78px;
///					}
///				}
///
///				p {
///					@include media($desktop-extra-min) {
///						font-size: 16px;
///					}
///				}
///			}
///
///			&:nth-child(3) {
///				span {
///					max-width: 90px;
///				}
///			}
///
///			&:nth-child(4) {
///				span {
///					margin-left: 6px;
///					max-width: 96px;
///				}
///			}
///		}
///	}

	.section-quote {
		background: #f2f2f2;
		background-repeat: no-repeat;
		background-size: cover;
		padding: 64px 20px;
		text-align: center;
		margin-bottom: 5rem;

		@include media(767px) {
			background-image: none !important;
		}

		@include media-min($mobile-max) {
			border-radius: 9px;
			padding: 78px 60px;
		}

		@include media-min($desktop-min) {
			height: 790px;
			background-color: #c1c1c1;
			padding: 153px 0;
			text-align: center;
		}

		img {
			display: none;

			@include media-breakpoint-up(lg) {
				display: inline-block;
				vertical-align: top;
				border-radius: 9px 0 0 9px;
			}
		}

		div {
			position: relative;
			background-color: #fff;
			border-radius: 9px;

			@include media-breakpoint-up(lg) {
				border-radius: 0 9px 9px 0;
			}

			@include media-min($desktop-min) {
				display: inline-block;
				width: 423px;
				height: 482px;
			}

			p {
				font-size: 1.5em;
				padding: 1.5em;
				border-radius: 9px;

				@include media-breakpoint-up(lg) {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					padding: 0 1.5em;
					text-align: left;
				}
			}
		}

		&.section-quote-high {
			@include media-min($desktop-min) {
				height: auto;
			}

			div {
				padding: 4em 0;

				@include media-min($desktop-min) {
					height: 600px;
				}

				p {
					@include media-breakpoint-up(lg) {
						position: static;
						top: 0;
						transform: none;
					}
				}
			}
		}
	}

	.section-details {
		padding-bottom: 80px;
		padding-top: 80px;
		position: relative;
		z-index: 9;

		@include media($mobile-min) {
			padding: 50px 20px 53px;
		}

		@include media($tablet-min) {
			padding-bottom: 50px;
			padding-top: 53px;
		}

		@include media($desktop-min) {
			flex-direction: column;
			padding-bottom: 99px;
			padding-top: 75px;
		}

		.row {
			min-width: 100%;
		}

		&-description {
			text-align: center;

			h2 {
				font-size: 30px;
				margin: 0 0 23px;
				text-transform: none;
				color: #87212e;

				@include media-min($mobile-max) {
					font-size: 36px;
					line-height: 36px;
				}

				@include media-min($desktop-min) {
					font-size: 38px;
					line-height: 40px;
					margin: 0;
				}
			}

			p {
				font-size: 22px;
				margin-top: 47px;

				@include media($desktop-min) {
					font-size: 16px;
					line-height: 30px;
				}
			}
		}

		&-list {
			padding-left: 15px;

			@include media-min($desktop-min) {
				padding-left: 80px;
			}

			@include media-min($desktop-extra-min) {
				padding-left: 130px;
			}

			&-item {
				flex-direction: column;
				max-width: 513px;
				margin-top: 5rem;
				text-align: center;

				@include media-min($mobile-max) {
					flex-direction: row;
					text-align: left;
				}

				///&:first-child {
				///	margin-top: 0;
				///}

				img {
					border-radius: 9px;
					margin-bottom: 2.5rem;
				}

				div {
					max-width: 100%;
				}

				&-title {
					color: #87212e;
					display: block;
					font: 26px/30px 'Avenir LT W01_95 Black1475556',sans-serif;
					letter-spacing: 1px;
					margin: 0 0 20px;
					text-transform: uppercase;

					@include media-min($mobile-max) {
						font-size: 30px;
						margin: 0;
					}
					
					a, a:hover, a:focus, a:visited {
						color: #87212e;
					}
				}

				p {
					font-size: 22px;
					line-height: 30px;
					margin-top: 14px;
					max-width: 489px;

					@include media($desktop-min) {
						max-width: 100%;
					}
				}
			}
		}
	}

	.section-in-touch {
		background-color: #f2f2f2;
		background-repeat: no-repeat;
		max-width: 1440px;
		padding: 67px 20px 72px;

		@include media-breakpoint-down(md) {
			background-image: none !important;
		}

		@include media-min($desktop-min) {
			background-position: center top;
			background-size: cover;
			min-height: 600px;
			max-height: inherit;
			padding: 128px 20px;
		}

		&-action {
			border-radius: 9px;
			background-color: #fff;
			padding: 35px 30px 30px;
			text-align: left;

			@include media-min(360px) {
				padding: 45px 50px 40px;
			}

			@include media-min($tablet-min) {
				margin: 0;
				max-width: 100%;
				padding: 45px 50px 42px;
				text-align: center;
			}

			@include media-min($desktop-min) {
				margin: 0;
				max-width: 456px;
				padding: 4.2em 3.8em 4.1em 4.9em;
				text-align: left;
			}

			&-title {
				display: block;
				font: 46px/1.1em 'Avenir LT W01_85 Heavy1475544',sans-serif;
				letter-spacing: 1px;
				margin: 0;
				color: #87212e;

				@include media($mobile-min) {
					font-size: 33px;
				}
			}

			p {
				font-size: 1.2em;
				margin-top: 20px;
			}

			.btn-primary {
				margin-top: 10px;
				min-width: 221px;

				@include media($tablet-min) {
					width: 100%;
					padding-left: 1em;
					padding-right: 1em;
				}
			}
		}
	}
}

section.why {
	@include make-container();
	@include make-container-max-widths();

	margin-bottom: 5rem;

	& > h2 {
		font: 38px/40px "Avenir LT W01_85 Heavy1475544", sans-serif;
		text-transform: none;
		text-align: center;
		margin-bottom: 3rem;
		color: #87212e;

		@include media-breakpoint-up(lg) {
			margin-bottom: 4rem;
		}
	}

	& > div {
		@include make-row();
		margin-bottom: 2em;

		& > div {
			@include make-col-ready();

			padding: 3em 2em;
			max-width: 40em;
			margin: 0 auto;
			text-align: center;

			@include media-breakpoint-up(sm) {
				padding-left: 5em;
				padding-right: 5em;
			}

			@include media-breakpoint-up(lg) {
				@include make-col(6);

				padding-top: 4em;
				paddign-bottom: 4em;

				&:nth-of-type(odd) {
					border-right: 6px solid #ddd;
				}

				&:nth-of-type(n+3), &:nth-of-type(n+4) {
					border-top: 6px solid #ddd;
				}
			}

			.img-box {
				position: relative;
				height: 83px;
				margin-bottom: 1.5em;
				& > img {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}

			& > h3 {
				margin-bottom: 1em;
			}
		}
	}

	& > p {
		text-align: center;

		a, a:hover, a:visited, a:active, a:focus {
			color: inherit;
			text-decoration: underline;
		}
	}

	& > img {
		display: block;
		margin: 1em auto;
	}
}

h2.home-msn {
	font: 38px/40px "Avenir LT W01_85 Heavy1475544",sans-serif;
	text-transform: none;
	text-align: center;
	margin-bottom: 3rem;
	color: #87212e;
}

.section-msn-first {
	margin-top: 6rem;
	margin-bottom: 6rem;

	h2 {
		font: 38px/40px "Avenir LT W01_85 Heavy1475544",sans-serif;
		text-transform: none;
		text-align: center;
		margin-bottom: 3rem;
		color: #87212e;
	}

	img {
		border-radius: 9px;
	}

	p {
		font-size: 20px;
	}
}

.badges {
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	max-width: 800px;
	margin: 0 auto 5rem;

	img {
		margin: 0 15px 30px;
		max-height: 200px;
		max-width: 40%;
		min-width: 130px;
	}
}

.badges-copy {
	padding: 25px;
	max-width: 485px;
	margin: 0 auto 5rem;
	text-align: center;
	background-color: #262626;
	color: #fff;
}
