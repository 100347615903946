.testimonials {
    position: relative;
    z-index: 1;
    margin: 0 0 40px;

    p {
        text-align: center;
        font-size: 21px;
        line-height: 32px;
    }

    .splide__pagination {
        position: relative;
        margin: 0 !important;
        padding: 0;
        width: auto;
        height: 0;
        left: auto;
        bottom: -1em;
        right: auto;
        transform: none;

        li {
            font: initial !important;
        }
    }

    .splide__pagination__page {
        background: #EADD98;

        &.is-active {
            background: #87212e;
            transform: scale(1);
        } 
    }

    li {
        margin: 0 !important;
        padding: 0 !important;

        &:before {
            display: none;
        }
    }
}

body.home .testimonials {
    margin: 0 0 90px;
}
