.ccne {
	background: #F9F8F4;
	margin: 0 0 -67px;
	padding: 0 15px;

	@include desktop {
		margin: 0;
		padding: 0 15px 45px;
	}

	&-bg {
		bottom: 0;
		left: 0;
		position: absolute;
		opacity: .2;
		right: 0;
		top: 0;

		&::before {
			background: linear-gradient(90deg, #4F2C1D 0%, rgba(0, 0, 0, 0) 100%);
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 4;
		}

		img {
			display: block;
			height: 100%;
			object-fit: cover;
			position: relative;
			width: 100%;
			z-index: 3;
		}
	}

	&-wrapper {
		background: #4F2C1D;
		border-radius: 10px;
		margin: 0 auto;
		max-width: 1342px;
		overflow: hidden;
		padding: 34px 25px 107px;
		position: relative;
		width: 100%;

		@include desktop {
			align-items: center;
			border-radius: 20px;
			display: flex;
			padding: 58px 116px;
		}
	}

	&-description {
		padding: 52px 0 40px;
		position: relative;
		text-align: center;
		z-index: 4;

		@include desktop {
			padding: 52px 32px 0 0;
			text-align: left;
			width: calc(100% - 350px);
		}

		&::before {
			background: #FFC54E;
			border-radius: 6px 0 6px 0;
			content: '';
			left: calc(50% - 33px);
			height: 17px;
			position: absolute;
			transform: skewX(-15deg);
			top: 0;
			width: 65px;

			@include desktop {
				left: 0;
				width: 68px;
			}
		}

		p {
			color: #fff;
			font: 400 16px/24px $default-font;

			@include desktop {
				font: 400 21px/32px $default-font;
			}
		}

		a {
			color: #fff;
			font-weight: 900;
			text-decoration: underline;
			
			&:hover {
				text-decoration: none;
			}
		}
	}

	&-logo {
		align-items: center;
		background: #fff;
		border-radius: 20px;
		display: flex;
		height: 160px;
		margin: 0 auto;
		position: relative;
		width: 160px;
		z-index: 4;

		@include desktop {
			height: 350px;
			margin: 0;
			width: 350px;
		}

		img {
			display: block;
			height: auto;
			margin: auto;
			max-width: 100px;

			@include desktop {
				max-width: 100%;
			}
		}
	}
}