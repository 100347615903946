.footer-main {
	display: flex;
	flex-direction: column;
	padding: 40px 20px 30px;
	text-align: center;
	width: 100%;
	background: #87212e;
	color: #fff;

	@include media-min($mobile-max) {
		padding: 40px 0 30px;
	}

	@include media-min($desktop-min) {
		flex-direction: row;
		padding: 40px 0 15px;
		text-align: left;
	}

	&.gold {
		padding-top: 35px;
		position: relative;

		&:before{
			background: #ffc54e;
			content: '';
			left: 0;
			height: 250px;
			position: absolute;
			right: 0;
			top: 0;

			@include media-min($mobile-max) {
				height: 115px;
			}
		}
		
		hr{
			border: none;
			margin: 35px 0 0;

			@include media-min($desktop-min) {
				margin-top: 35px;
				margin-bottom: 30px;
			}
		}
	}

	&-logo {
		width: 164px;

		@include media($desktop-min) {
			height: 37px;
			margin: auto auto 18px;
			order: 3;
		}

		@include media($tablet-min) {
			width: auto;
		}

		img{
			display: block;
			max-width: 164px;
			width: 100%;
		}
	}

	&-phone {
		font-size: 16px;
		padding-bottom: 9px;
		padding-top: 7px;
		white-space: nowrap;

		@include media($desktop-extra-min) {
			font-size: 15px;
		}

		@include media($desktop-min) {
			font-size: 15px;
			margin: 0 0 15px;
			order: 5;
			text-align: center !important;
		}
	}

	.copyright {
		font-size: 12px;

		@include media($desktop-min) {
			order: 7;
		}
	}

	.footer-disclaimer {

		@include media($desktop-min) {
			order: 6;
			text-align: center;
		}

		p {
			font-size: 12px;
			text-align: left;
			padding: 0 0 15px;

				a {
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}
				}
		}
	}

	.address {
		font-size: 14px;

		@include media($desktop-min) {
			margin: 0;
			order: 4;
		}
	}

	.privacy-and-terms{
		@include media($desktop-min) {
			order: 8;
			text-align: center !important;
		}
	}

	.privacy-policy {
		margin-left: auto;
		font-size: 12px;

		@include media($desktop-min) {
			margin: 0;
		}
	}

	.terms {
		font-size: 12px;
	}

	a {
		color: #fff;
	}

	hr {
		background-color: #bfbfbf;
		margin-bottom: 30px;
		margin-top: 0;
		min-width: 100%;
		order: 2;

		@include media-min($desktop-min) {
			margin-bottom: 30px;
			margin-top: 40px;
			order: 0;
		}
	}
}