* {
	box-sizing: border-box;
	transition: 0.25s ease;
	transition-property: background, border, box-shadow, color, left, margin, padding, right, opacity;

	*::before, *::after {
		box-sizing: border-box;
	}
}

html, body {
	height: 100%;
}

body {
	background-color: #fff;
	color: $default-color-text;
	font-family: $default-font;
	font-size: 16px;
	line-height: 1.5;
	margin-left: auto;
	margin-right: auto;
}

input[type='text'],input[type='email'],input[type='tel'],input[type='password'],textarea,select{
	background-color: #fcfcfc;
	border-color: #ccc;
	border-radius: 0;
	box-shadow: none;
	padding: 13px 22px;
	width: 100%;

	&:focus{
		box-shadow: none;
	}

	&:focus:required:invalid{
		color: #666;
	}

	&:required:valid{
		color: #666;
	}

	&::placeholder{
		color: #666;
	}

	&.error-input{
		box-shadow: 0 0 3px 1px #f00;
	}
}

textarea{
	height: 180px;
	padding: 16px 0 0 16px;
	resize: none;
}

a {
	text-decoration: none;
	cursor: pointer;

	&:not([href]){
		cursor: initial;
	}

	&[href^="http"]:empty::before {
		content: attr(href);
	}
}

h1,
h2,
h3,
h4 {
	font-family: 'Avenir LT W01_95 Black1475556', sans-serif;
	letter-spacing: 1px;
	margin: 0;
	text-transform: uppercase;
}

h1 {
	font-size: 5em;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 30px;
}

p {
	font-size: 18px;
	margin: 0 0 1em;

	&:last-child{
		margin: 0;
	}
}

strong {
	font-family: 'Avenir LT W01_95 Black1475556', sans-serif;
	font-weight: 500;
}

a:hover {
	text-decoration: none;
}

img {
	max-width: 100%;
	height: auto;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
