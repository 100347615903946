.thank-you {
	padding: 70px 0 40px;
	text-align: center;

	&-description {
		padding: 0 15px;

		h3 {
			font-family: $default-font;
			font-size: 70px;
			font-weight: 600;
			text-transform: uppercase;

			@include tablet {
				font-size: 54px;
			}
		}

		p {
			font-family: $default-font;
			font-size: 28px;
			line-height: 1.5;
			font-weight: 600;
			margin: 0 auto;
			max-width: 800px;
			text-transform: uppercase;

			@include tablet {
				font-size: 20px;
			}
		}
	}

	&-posts {
		background: #f1f1f1;
		margin: 30px 0;
		padding: 40px 15px;

		.container {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&-post {
		align-items: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		height: 275px;
		margin: 0 0 24px;
		padding: 12px;
		position: relative;
		text-align: center;
		width: 100%;

		@include desktop {
			margin: 0 12px;
			width: calc(100% / 3 - 24px);
		}

		&:last-child {
			margin-bottom: 0;
		}

		&::after {
			background: rgba(#000,.5);
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}

		h4 {
			position: relative;
			z-index: 9;
		}

		a {
			background: url(../img/icon-arrow.svg) no-repeat center bottom 7px;
			background-size: 32px 32px;
			color: #fff;
			display: inline-flex;
			font-weight: 700;
			padding: 20px 0 60px;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&-social {
		padding: 0 15px;
		text-align: center;

		&-title {
			margin: 0 0 12px;
		}

		&-links {
			display: flex;
			justify-content: center;
		}

		a {
			align-items: center;
			display: flex;
			justify-content: center;
			margin: 0 8px;
			max-width: 24px;

			img {
				display: block;
				height: auto;
				width: 24px;
			}
		}
	}

	&-testimonials {
		background: #f1f1f1;
		margin: 30px 0;
		padding: 40px 15px;

		.container {
			display: flex;
			flex-wrap: wrap;
		}

		&-title {
			padding: 10px 0 25px;
		}

		.testimonial {
			margin: 0 0 24px;
			padding: 12px;
			text-align: center;
			width: 100%;

			@include desktop {
				margin: 0 12px;
				width: calc(100% / 3 - 24px);
			}

			img {
				border-radius: 50%;
				width: 200px;
				height: 200px;
			}

			&-text {
				padding: 20px 0 0;
			}

			&-name {
				font-style: italic;
				padding: 20px 0 0;
			}
		}
	}
}
