.main-nav {

	&-top {
		background: #87212e;
		color: #fff;
		display: none;

		@include media-min($mobile-max) {
			display: block;
			font-size: 21px;
			position: absolute;
			top: 100%;
			width: 100%;
			z-index: 1;
		}

		nav{
			width: 100%;

			@include media-min($desktop-min) {
				width: auto;
			}

			ul{
				justify-content: space-between;

				@include media-min($desktop-min) {
					justify-content: flex-start;
				}
			}

			.menu-item-has-children::after {
				content: " ";
				width: 0;
				height: 0;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-top: 6px solid #ffc54e;
				position: relative;
				top: .75em;
				left: 0.5em;
			}

			& > ul > li {
				&:first-child {
					& > a {
						padding-left: 0;
					}
				}

				&:last-child {
					& > a {
						padding-right: 0;
					}
				}
			}

		}

		li {
			@include media($desktop-min) {
				text-align: center;
				width: 100%;
				white-space: nowrap;
			}
		}

		a {
			color: #fff;
			padding: 20px 38px;

			&.current_page_item {
				color: #ffc54e;
			}

			@include media($desktop-extra-min) {
				padding: 15px 15px;
			}

		}

		ul.sub-menu {
			position: absolute;
			visibility: hidden;
			opacity: 0;
			transition: opacity ease-in-out .2s;

			background: #942E3B;
			font-size: 0.8em;
			min-width: 100%;
			white-space: nowrap;
		}

		li:hover > ul.sub-menu {
			visibility: visible;
			opacity: 1;
		}
	}

	&-footer {
		font-size: 16px;
		white-space: nowrap;

		@include media($desktop-min) {
			order: 1;
			margin-bottom: 40px;
			white-space: normal;
		}

		ul{
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;

			@include media-min($mobile-max) {
				flex-direction: row;
			}
		}

		li{
			padding: 0 23px;

			@include media($desktop-extra-min) {
				padding: 0 10px;
			}

			@include media($tablet-min) {
				padding: 0;
			}
		}

		a {
			padding-bottom: 12px;
			padding-top: 12px;
		}

		ul.sub-menu {
			display: none;
		}
	}

	a {
		display: inline-block;
		font-family: 'Avenir LT W01_85 Heavy1475544', sans-serif;
		letter-spacing: 1px;
		line-height: 1;
		text-transform: uppercase;

		@include media($desktop-extra-min) {
			font: 16px/16px 'Avenir LT W01_85 Heavy1475544', sans-serif;
		}

		@include media($desktop-min) {
			font: 16px/16px 'Avenir LT W01_85 Heavy1475544', sans-serif;
		}
	}

	&.main-nav-footer {
		a {
			font-size: 14px;

			@include media($desktop-extra-min) {
				///font-size: 14px/14px;
				font: 13px/14px 'Avenir LT W01_85 Heavy1475544', sans-serif;
			}

			@include media($desktop-min) {
				///font: 16px/16px;
				font: 16px/16px 'Avenir LT W01_85 Heavy1475544', sans-serif;
			}
		}
	}
}
