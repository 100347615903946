.alignleft {
	float: left;
	margin: 0 70px 50px 0;
}

.alignright {
	float: right;
	margin: 0 0 50px 70px;
}

.aligncenter {
	display: block;
	margin: 0 auto 50px;
}

p{
	.alignleft {
		margin-top: 47px;
	}

	.alignright {
		margin-top: 47px;
	}

	.aligncenter {
		margin-top: 47px;
	}
}

@keyframes animate {
	from {
		transform: scale3d(1, 1, 1);
	}
	50% {
		transform: scale3d(1.15, 1.15, 1.15);
	}
	to {
		transform: scale3d(1, 1, 1);
	}
}
.animation {
	animation-name: animate;
}

.wrapper {
	position: relative;
}

.container {
	padding-left: 0;
	padding-right: 0;
	position: relative;
}

.container-fluid {
	padding-left: calc((100% - 1140px) / 2);
	padding-right: calc((100% - 1140px) / 2);
	position: relative;
}

.page-wrapper-center {
	margin: 0 auto;
	max-width: 1440px;
}