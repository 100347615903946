body.page-template-refer-a-friend main {

	.section-hero {
		@include media($desktop-min) {
			padding: 92px 15px;
		}

		@include media($tablet-min) {
			padding: 46px 15px;
		}

		padding: 46px 15px;

		.container {
			height: auto;
			min-height: initial;

			h1 {
				font-size: 54px;

				@include media($tablet-min) {
					font-size: 38px;
					line-height: 42px;
				}
			}
		}
	}

	.section-content {
		h2 {
			font-size: 30px;
			text-align: center;
		}

		h3 {
			font-size: 20px;
			margin: 5px 0 0;
		}
	}

	.awards {

		img {
			display: block;
			height: auto;
			margin: 0 auto 30px;
			max-width: 132px;
		}

		.badges img {
			margin: 0 20px 20px;
		}
	}
}